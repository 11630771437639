/* Common */

@import "bootstrap_custom";

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy semi';
    src: url('#{$font-path}/Gilroy-Semi-Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('#{$font-path}/Gilroy-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

.btn {
    font-weight: 600 !important;
}

label {
    font-weight: 600 !important;
}

.male {
    color: #287FD0;
}

.female {
    /*color: #F88B9D;*/
    color: #EE724B;
}

/* Custom modal */

.modal-header {
    padding-right: 0 !important;
    align-items: center !important;

    button.close {
        margin: 0 !important;
        border-top-left-radius: 24px !important;
        border-bottom-left-radius: 24px !important;
        background-color: #f6f6f6 !important;
    }

    .close {
        padding: 1rem 2rem 1rem 1rem !important;
    }

    .close {
        /*padding: 2rem 4rem;*/
        margin: 0rem -3rem 0rem auto !important;
    }
}

/* left/right Modal */

.modal-slideout {
    padding-top: 62px;
    padding-right: 0 !important;
}


.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
    background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(100%, 0) scale(1);
    transform: translate(100%, 0) scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
    border: 0;
}

.modal-dialog-slideout .modal-footer {
    padding: 1rem;
}

/*
.modal-dialog-slideout .modal-header, .modal-dialog-slideout .modal-footer {
    height: 69px;
    display: block;
}*/

.modal-dialog-slideout .modal-header h5 {
    float: left;
}

.bg-primary-light {
    background-color: lighten($primary, 30%) !important;
}

.primary-input-group .input-group-text {
    color: $primary !important;
    border-color: $primary !important;
}

.primary-input-group .form-control {
    border-color: $primary !important;
}

.primary-input-group .form-control::placeholder {
    color: lighten($primary, 20) !important;
}


.danger-input-group .input-group-text {
    color: $red !important;
}

.danger-input-group .input-group-text {
    border-color: $red !important;
}

.danger-input-group .form-control {
    border-color: $red !important;
}

.danger-input-group .form-control::placeholder {
    color: lighten($red, 20) !important;
}

.card-empty-user .card-body {
    padding: 80px;
}

@media (min-width: 768px) {

    .card-empty-account {
        background-image: url('/assets/img/Imagery-Money-4.png');
        background-position: 100% 10%;
        background-size: 550px;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }

    .card-order {
        background-image: url('/assets/img/Imagery-Money-3.png');
        background-position: 100% 40%;
        background-size: 460px;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }

    .card-empty-user .card-body {
        background-image: url('/assets/img/Imagery-Empty-3.png');
        background-position: 100% 50%;
        background-size: 48%;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }
}

.breadcrumb {
    margin-bottom: 0 !important;
    background-color: #D4E5F6 !important;
}
