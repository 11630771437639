@import "bootstrap_override";

html, body {
    height: 100% !important;
}

body {
    --body-color: #F8F8F8;
    background-color: var(--body-color) !important;
    position: relative;
}

.macartevacances {
    color: $primary;
    font-family: Gilroy, sans-serif;
    font-weight: 700;
}

.bg-back {
    background-color: #F8F8F8;
}

.text-primary-alt {
    color: $primary !important;
}

/* Nabar */

.navbar {
    font-weight: 700;
}

hr {
    height: 8px;
    background-image: linear-gradient(180deg, hsla(0, 0%, 68.2%, 0.05), transparent);

    border: 0;
}

.shadowed {
    filter: drop-shadow(5px 5px 5px #eee);
}

.table-no-header tr th {
    border: 0;
}

.table-no-header tr:first-child td {
    border: 0;
}

small {
    font-size: 1rem;
    font-weight: 500;
    color: rgb(174, 174, 174);
}

.text-grey {
    color: rgb(102, 102, 102);
}

.table-bigrow {
    border-collapse: separate;
    border-spacing: 0 4px !important;
}

.table-bigrow-hover tbody tr:hover {
    background-color: rgb(245, 250, 252) !important;
}

.table-bigrow th {
    border: 2px solid #fafafa;
    border-radius: 0;
    vertical-align: middle;
}

.table-bigrow td {
    border: 2px solid #fafafa !important;
    border-radius: 0 !important;
    vertical-align: middle !important;
    border-left: none !important;
    border-right: none !important;
}

.table-bigrow tbody tr td:first-child {
    border-left: 2px solid #fafafa !important;
    border-top-left-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
}

.table-bigrow tbody tr td:last-child {
    border-right: 2px solid #fafafa !important;
}

.table-bigrow th {
    border-right: none !important;
}

.selected-bigrow th {
    color: $primary !important;
}

.table-bigrow tbody tr.selected-bigrow {
    background-color: rgb(245, 250, 252) !important;
    border: 2px solid rgb(219, 245, 255) !important;
}

.square-48 {
    width: 48px;
    height: 48px;
    text-align: center;
    vertical-align: middle;
    line-height: 48px;
}

.square-30 {
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
}

.rounded-1 {
    border-radius: 1rem !important;
}

.credit {
    background-color: rgba(40, 167, 69, 0.25);
    padding: 0.25em;
    border-radius: 0.25em;
}

val-errors {
    color: $danger;
}

select.ng-invalid.ng-touched, input.ng-invalid.ng-touched, ng-select.ng-invalid.ng-touched > div.ng-select-container {
    border-color: $danger;
}

.rounded-2 {
    border-radius: 0.5em;
}

.rounded-top-20 {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.rounded-bottom-20 {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.spinner-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

div.mat-sort-header-indicator {
    color: $primary;
}


.tags.a-commander {

    background-color: rgba(255, 152, 0, 0.15);
    color: rgb(255, 152, 0);
}

.tags.a-recharger {
    background-color: rgba(40, 167, 69, 0.15);
    color: #28A745;
}

.tags.acommander {
    background-color: rgba(255, 152, 0, 0.15);
    color: rgba(255, 152, 0, 1);
}

.tags.commandees {
    background-color: rgba(40, 167, 69, 0.15);
    color: rgba(40, 167, 69, 1);
}

.tags.enstock {
    background-color: rgba(255, 193, 7, 0.15);
    color: rgba(255, 193, 7, 1);
}

.tags.acommander_recharge {
    background-color: rgba(212, 229, 246, 0.15);
    color: rgba(212, 229, 246, 1);
}

.tags.activees {
    background-color: rgba(23, 162, 184, 0.15);
    color: rgba(23, 162, 184, 1);
}

.tags.cancel {
    background-color: rgba(197, 7, 7, 0.15);
    color: rgba(197, 7, 7, 1);
}

.tags.empty {
    background-color: rgba(102, 102, 102, 0.15);
    color: rgba(102, 102, 102, 0.5);
}

.tags {
    white-space: nowrap;
    height: 28px;
    padding: 2px 15px;
    font-weight: 700;
    text-align: center;
    border-radius: 0.2rem;
}

.text-barre {
    text-decoration: line-through;
    color: rgba(102, 102, 102, 0.5);
}


.tags.nouveau {
    background-color: rgba(255, 152, 0, 0.15);
    color: #ff9800;
}

.tags.ok {
    background-color: rgba(40, 167, 69, 0.15);
    color: #28A745;
}

.tags.deleted {
    background-color: rgba(255, 152, 0, 0.15);
    color: #ff9800;
}

.tags.ko {
    background-color: rgba(197, 7, 7, 0.15);
    color: #c50707;
}

.tags.empty {
    background-color: rgba(102, 102, 102, 0.15);
    color: rgba(102, 102, 102, 0.5);
}

.tags {
    white-space: nowrap;
    height: 28px;
    /*min-width: 0.875rem;*/
    /*display: block;
    width: 75%;*/
    padding: 2px 15px;
    font-weight: 700;
    text-align: center;
    border-radius: 0.2rem;
}
